<template>
  <base-dialog customActivator :title="$t('confirmOrder')">
    <template #custom-activator="{ events: { handleOpenDialog } }">
      <BaseButton
        keyTextButtonTranslate="finishOrder"
        :disabled="disableFinishOrderBtn"
        :handlerClick="() => (conditionValid ? handleOpenDialog() : () => {})"
      />
      <v-alert
        text
        :value="disableFinishOrderBtn && conditionValid"
        type="info"
        dense
        border="left"
        class="mt-3"
      >
        {{ $t("badSettingOrder") }}
      </v-alert>
    </template>
    <template #content>
      <InfoOrder :priceDomicileService="priceDomicileService" />
      <FormRedsys v-if="formRedsysData" :formData="formRedsysData" />
    </template>
    <template
      #actions="{ events: { handleCloseDialog } }"
      class="flex-column flex-sm-row flex-wrap justify-space-between"
    >
      <base-btn
        color="primary"
        class="btn-action my-2 mx-0"
        @click="handleCloseDialog"
      >
        {{ $t("back") }}
      </base-btn>
      <base-btn
        color="success"
        class="btn-action my-2 mx-0"
        :loading="loading"
        :disabled="loading"
        @click="handlerFinishOrder"
      >
        {{ $t("continue") }}
      </base-btn>
    </template>
  </base-dialog>
</template>
<script>
import { i18n } from "@/plugins/i18n";
import {
  orderTypeName,
  orderTypeStoreGetters,
  orderTypeStoreActions,
} from "@/store/modules/orderType/constNames.js";
import { TYPE_PAYMENT } from "./enums";
import { getErrorPayloadForSnackbar, getIsKioskSetting } from "@/helpers";
import {
  settingCtrl,
  paymentRedsysCtrl,
  shoppingCartCtrl,
} from "@/controllers";
import { useSnackbar } from "@/utils";

export default {
  name: "FinishOrderBtn",
  components: {
    BaseButton: () => import("@/components/shared/BaseButtons/Index"),
    FormRedsys: () => import("@/components/paymentRedsys/FormRedsys"),
    InfoOrder: () => import("./InfoOrder"),
  },
  props: {
    conditionValid: { type: Boolean, default: true },
    priceDomicileService: { type: Number, default: 0 },
  },
  data() {
    return {
      loading: false,
      formRedsysData: null,
    };
  },
  methods: {
    async handlerFinishOrder() {
      try {
        if (!this.conditionValid) return;
        this.loading = true;
        const order = this.getOrderToSend;
        this.checkDifferenceHoursForTable(order);
        const methodPay = await settingCtrl.getMethodPay(
          this.IHaveSelectedAnOrderType
        );
        methodPay === TYPE_PAYMENT.CASH
          ? await this.payCashProcess(order)
          : await this.payCardProcess(order);
      } catch (error) {
        console.log(error);
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
      this.loading = false;
    },
    checkDifferenceHoursForTable(order) {
      if (this.orderTypeIsToHomeOrToPickUp) return;
      const timeSaved = moment(
        localStorage.getItem("time") || new Date().getTime()
      );
      const now = moment();
      const difference = now.diff(timeSaved, "hours");
      if (difference > 2 || !order.qr) {
        this.$store.commit("user/OPEN_SNACKBAR", {
          msg: i18n.t("scanQrAgain"),
          success: false,
        });
        return this.$router.replace({ name: "ScanQr" });
      }
    },
    async payCashProcess(order) {
      const { data, message } = await shoppingCartCtrl.handleSendOrder(order);
      if (data && message) {
        this.$router.replace({ name: "ThankYou" });
        const snackbar = useSnackbar();
        snackbar.dispatchOpenSnackbar(message, i18n.t("successRequest"));
        const orderType =
          this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
        orderType !== orderTypeName.LOCAL &&
          !this.isKiosk &&
          this.$store.dispatch(orderTypeStoreActions.REMOVE_ORDER_TYPE);
        this.$store.dispatch("cart/clearCart");
      }
    },
    async payCardProcess(order) {
      const { data } = await paymentRedsysCtrl.handleSendOrder(order);
      data && (this.formRedsysData = { ...data });
    },
  },
  computed: {
    isKiosk() {
      return getIsKioskSetting;
    },
    getOrderToSend() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TO_SEND];
    },
    disableFinishOrderBtn() {
      const { qr } = this.$store.getters[orderTypeStoreGetters.GET_ORDER_LOCAL];
      return (
        !this.IHaveSelectedAnOrderType ||
        (this.orderTypeIsToHome && !this.IHaveSelectedAnAddress) ||
        (!this.orderTypeIsToHomeOrToPickUp && !qr) ||
        !this.conditionValid
      );
    },
    IHaveSelectedAnOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    IHaveSelectedAnAddress() {
      return this.$store.getters[orderTypeStoreGetters.GET_ADDRESS];
    },
    orderTypeIsToHomeOrToPickUp() {
      return this.orderTypeIsToHome || this.orderTypeIsToPickUp;
    },
    orderTypeIsToHome() {
      return this.IHaveSelectedAnOrderType === orderTypeName.AT_HOME;
    },
    orderTypeIsToPickUp() {
      return this.IHaveSelectedAnOrderType === orderTypeName.TO_PICK_UP;
    },
  },
};
</script>

<style lang="sass">
.btn-action
  min-width: 107px !important
</style>
